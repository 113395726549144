<template>
  <div id="app">
    <a-locale-provider :locale="locale">
      <router-view />
    </a-locale-provider>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        locale: window.antd.locales.zh_CN,
      };
    },
  }
</script>

<style>
  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
  }
/* @media screen and (min-width: 1024px) {
  #app {
    min-width: 1440px;
  }
} */

</style>